<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("PAGES.COMPANIES.OVERVIEW.COMPANY_INFORMATION.TITLE") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="enabledEditing()"
          ref="kt_save_changes"
        >
          {{ editDataButton }}
        </button>

        <button
          type="reset"
          class="btn btn-success grey mr-2"
          @click="save()"
          ref="kt_save_changes"
          v-if="!isDisabled"
        >
          {{ $t("FORM.CANCEL") }}
        </button>
        <button
          type="reset"
          class="btn btn-success green mr-2"
          @click="save()"
          ref="kt_save_changes"
          v-if="!isDisabled"
        >
          {{ $t("FORM.SAVE") }}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <v-row>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="singleItem.id"
            :disabled="true"
            filled
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.CODE')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="singleItem.consultant.username"
            :disabled="isDisabled"
            filled
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.CONSULTANT_ID')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            :value="
              singleItem.createdAt != null
                ? new Date(singleItem.createdAt).toLocaleString('it-IT')
                : '---'
            "
            :disabled="true"
            filled
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.CREATED_AT')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            :value="
              singleItem.updatedAt != null
                ? new Date(singleItem.updatedAt).toLocaleString('it-IT')
                : '---'
            "
            :disabled="true"
            filled
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.UPDATED_AT')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-select
            :items="status"
            item-text="name"
            item-value="value"
            v-model="singleItem.status"
            filled
            :disabled="isDisabled"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.STATUS')"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateSubscriptionDate"
            :close-on-content-click="true"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                prepend-inner-icon="event"
                v-model="subscriptionDate"
                :disabled="isDisabled"
                filled
                color="blue-grey lighten-2"
                :label="$t('PAGES.COMPANIES.FORM.MANAGE.SUBSCRIPTION_DATE')"
                readonly
                hide-details
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="it-it"
              v-model="singleItem.subscription_date"
              no-title
              @input="subscriptionDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateSuspensionDate"
            :close-on-content-click="true"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                prepend-inner-icon="event"
                v-model="suspensionDate"
                :disabled="isDisabled || singleItem.status != 0"
                filled
                color="blue-grey lighten-2"
                :label="$t('PAGES.COMPANIES.FORM.MANAGE.SUSPENSION_DATE')"
                readonly
                hide-details
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="it-it"
              v-model="singleItem.suspension_date"
              no-title
              @input="suspensionDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            v-model="dateDeletionDate"
            :close-on-content-click="true"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
            max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                prepend-inner-icon="event"
                v-model="deletionDate"
                :disabled="isDisabled || singleItem.status != 2"
                filled
                color="blue-grey lighten-2"
                :label="$t('PAGES.COMPANIES.FORM.MANAGE.DELETION_DATE')"
                readonly
                hide-details
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="it-it"
              v-model="singleItem.deletion_date"
              no-title
              @input="dateDeletionDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" xl="6" lg="9">
          <h5 class="font-weight-bold mb-1">
            {{ $t("PAGES.COMPANIES.FORM.MANAGE.SUB_TITLE_BOX_1") }}
          </h5>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="singleItem.denomination"
            :disabled="isDisabled"
            filled
            dense
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.DENOMINATION')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="singleItem.vat"
            :disabled="isDisabled"
            filled
            dense
            :rules="numberRule"
            @keypress="isNumber($event)"
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.VAT')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="singleItem.fiscal_code"
            :disabled="isDisabled"
            filled
            dense
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.FISCAL_CODE')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="selectedCountry"
            :disabled="isDisabled"
            :items="countriesList"
            filled
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.COUNTRIES')"
            item-text="denomination"
            item-value="id"
            clearable
            @change="changeLocation($event, 'country')"
          >
            <template slot="selection" slot-scope="{ item, tile }">
              {{ item.denomination }} {{ tile }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.denomination }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="selectedRegion"
            :disabled="isDisabled || foreing_country"
            :items="regionsList"
            filled
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.REGIONS')"
            item-text="denomination"
            item-value="id"
            clearable
            @change="changeLocation($event, 'region')"
          >
            <template slot="selection" slot-scope="{ item, tile }">
              {{ item.denomination }} {{ tile }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.denomination }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="selectedState"
            :disabled="isDisabled || foreing_country"
            :items="statesList"
            filled
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.STATES')"
            item-text="denomination"
            item-value="id"
            clearable
            @change="changeLocation($event, 'state')"
          >
            <template slot="selection" slot-scope="{ item, tile }">
              {{ item.denomination }} {{ tile }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.denomination }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="selectedCity"
            :disabled="isDisabled || foreing_country"
            :items="citiesList"
            filled
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.CITIES')"
            item-text="denomination"
            item-value="id"
          >
            <template slot="selection" slot-scope="{ item, tile }">
              {{ item.denomination }} {{ tile }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.denomination }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="10">
          <v-text-field
            v-model="singleItem.address"
            :disabled="isDisabled"
            filled
            dense
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.ADDRESS')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="singleItem.cap"
            :disabled="isDisabled"
            filled
            dense
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.CAP')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="singleItem.position"
            :disabled="isDisabled"
            filled
            dense
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.POSITION')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="singleItem.inps"
            :disabled="isDisabled"
            filled
            dense
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.INPS')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="singleItem.inail"
            :disabled="isDisabled"
            filled
            dense
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.INAIL')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="selectedFiscalProvince"
            :disabled="(false && isDisabled) || foreing_country"
            :items="fullStateList"
            filled
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.FISCAL_STATE')"
            item-text="denomination"
            item-value="id"
            clearable
          >
            <template slot="selection" slot-scope="{ item, tile }">
              {{ item.denomination }} {{ tile }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.denomination }}
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" md="3">
          <v-select
            :items="status"
            item-text="name"
            item-value="value"
            v-model="singleItem.membership_subscription"
            filled
            :disabled="isDisabled"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.MEMBERSHIP_SUBSCRIPTION')"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            :items="status"
            item-text="name"
            item-value="value"
            v-model="singleItem.work_contract"
            filled
            :disabled="isDisabled"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.WORK_CONTRACT')"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="singleItem.association_type.id"
            :disabled="isDisabled"
            :items="association_type"
            filled
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.ASSOCIATION_TYPE')"
            item-text="denomination"
            item-value="id"
            clearable
          >
            <template slot="selection" slot-scope="{ item, tile }">
              {{ item.denomination }} {{ tile }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.denomination }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="singleItem.authority.id"
            :disabled="isDisabled"
            :items="authority_list"
            filled
            color="blue-grey lighten-2"
            :label="$t('PAGES.COMPANIES.FORM.MANAGE.AUTHORITY')"
            item-text="denomination"
            item-value="id"
            clearable
          >
            <template slot="selection" slot-scope="{ item, tile }">
              {{ item.denomination }} {{ tile }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.denomination }}
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-textarea
            clearable
            filled
            dense
            clear-icon="mdi-close-circle"
            :label="$t('PAGES.COMPANIES.ATTRIBUTES.NOTE')"
            v-model="singleItem.notes"
          ></v-textarea>
        </v-col>
      </v-row>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
import { FETCH_REG_USERS_LIST } from "@/core/services/store/reg_users.module";
import { FETCH_REGIONS_LIST } from "@/core/services/store/regions.module";
import {
  FETCH_STATES_LIST,
  FETCH_FULL_STATES_LIST,
  RESET_STATES_LIST,
} from "@/core/services/store/states.module";
import {
  FETCH_CITIES_LIST,
  RESET_CITIES_LIST,
  GET_CITY_BY_ID,
} from "@/core/services/store/cities.module";
import { FETCH_COUNTRIES_LIST } from "@/core/services/store/countries.module";

export default {
  name: "CitizenInformation",
  data() {
    return {
      isDisabled: true,
      foreing_country: false,
      users_filters: {
        query: [],
        filters: {
          _c1: {
            "member_group.is_banned": {
              _eq: false,
            },
          },
          _c2: {
            "member_group.is_admin": {
              _eq: true,
            },
          },

          _cmb_or: "_c1,_c2",
        },
        sort: {},
        page: 1,
      },
      empty_filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1,
      },
      regions_filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1,
      },
      status: [
        {
          value: 0,
          name: this.$t("PAGES.COMPANIES.FORM.MANAGE.NOT_ACTIVE"),
        },
        {
          value: 1,
          name: this.$t("PAGES.COMPANIES.FORM.MANAGE.ACTIVE"),
        },
        {
          value: 2,
          name: this.$t("PAGES.COMPANIES.FORM.MANAGE.DELETED"),
        },
      ],
      association_type: [
        {
          id: 1,
          denomination: "TEST association_type 1",
        },
        {
          id: 2,
          denomination: "TEST association_type 2",
        },
        {
          id: 3,
          denomination: "TEST association_type 3",
        },
      ],
      authority_list: [
        {
          id: 1,
          denomination: "TEST authority 1",
        },
        {
          id: 2,
          denomination: "TEST authority 2",
        },
        {
          id: 3,
          denomination: "TEST authority 3",
        },
      ],
      selectedCountry: {},
      selectedRegion: {},
      selectedState: {},
      selectedCity: {},
      selectedFiscalProvince: {},
      dateSuspensionDate: false,
      dateSubscriptionDate: false,
      dateDeletionDate: false,
    };
  },
  props: ["single-item"],
  mounted() {
    this.$store.dispatch(FETCH_REG_USERS_LIST, this.users_filters);
    // this.$store.dispatch(FETCH_MEMBER_GROUPS_LIST, this.empty_filters);
    this.$store.dispatch(FETCH_COUNTRIES_LIST, this.empty_filters);
    this.$store.dispatch(GET_CITY_BY_ID, this.singleItem.city.id);
    this.$store.dispatch(FETCH_FULL_STATES_LIST, this.empty_filters);
    // console.log("city", this.cityInUse);
    this.selectedCountry =
      this.cityInUse != undefined ? this.cityInUse.state.region.country.id : {};
    this.selectedRegion =
      this.cityInUse != undefined ? this.cityInUse.state.region.id : {};
    this.selectedState =
      this.cityInUse != undefined ? this.cityInUse.state.id : {};
    this.selectedCity = this.cityInUse != undefined ? this.cityInUse.id : {};
    this.selectedFiscalProvince =
      this.singleItem != undefined && this.singleItem != undefined
        ? this.singleItem.fiscal_province
        : {};
  },
  methods: {
    convertDataToPicker(date) {
      if (date != undefined) {
        return new Date(date.subscription_date).getDate();
        // return objDate.getDate();
        // return objDate.getFullYear()+"-"+objDate.getMonth()+"-"+objDate.getDay()D
      }
    },
    changeLocation: function (evt, type) {
      switch (type) {
        case "country":
          this.selectedCountry = evt;
          this.selectedRegion = null;
          this.selectedState = null;
          this.$store.dispatch(RESET_STATES_LIST);
          this.$store.dispatch(RESET_CITIES_LIST);
          this.selectedCity = null;
          break;
        case "region":
          this.selectedRegion = evt;
          this.selectedState = null;
          this.selectedCity = null;
          break;
        case "state":
          this.selectedState = evt;
          this.$store.dispatch(RESET_CITIES_LIST);
          this.selectedCity = null;
          break;
      }
    },
    enabledEditing() {
      this.isDisabled = !this.isDisabled;
    },
    save() {
      var name = this.$refs.name.value;
      var surname = this.$refs.surname.value;
      var company_name = this.$refs.company_name.value;
      var phone = this.$refs.phone.value;
      var email = this.$refs.email.value;
      var company_site = this.$refs.company_site.value;
      var photo = this.photo;

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch(UPDATE_PERSONAL_INFO, {
          name,
          surname,
          company_name,
          phone,
          email,
          company_site,
          photo,
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {
      this.$refs.name.value = this.currentUserPersonalInfo.name;
      this.$refs.surname.value = this.currentUserPersonalInfo.surname;
      this.$refs.company_name.value = this.currentUserPersonalInfo.company_name;
      this.$refs.phone.value = this.currentUserPersonalInfo.phone;
      this.$refs.email.value = this.currentUserPersonalInfo.email;
      this.$refs.company_site.value = this.currentUserPersonalInfo.company_site;
      this.current_photo = this.currentUserPersonalInfo.photo;
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
  },
  computed: {
    ...mapGetters([
      "currentUserPersonalInfo",
      "memberGroupsList",
      "regUsersList",
      "companyInUse",
      "countriesList",
      "cityInUse",
      "regionsList",
      "statesList",
      "citiesList",
      "fullStateList",
    ]),
    subscription_from_formatted() {
      return this.tableFilters.subscription_from
        ? this.tableFilters.subscription_from
        : "";
    },
    editDataButton() {
      return this.isDisabled
        ? this.$t("PAGES.COMPANIES.OVERVIEW.EDIT.TITLE_ENABLED")
        : this.$t("PAGES.COMPANIES.OVERVIEW.EDIT.TITLE_DISABLED");
    },
    suspensionDate() {
      return this.singleItem.suspension_date != null &&
        this.singleItem.status == 0
        ? new Date(this.singleItem.suspension_date).toLocaleDateString("it-IT")
        : "";
    },
    deletionDate() {
      return this.singleItem.deletion_date != null &&
        this.singleItem.status == 2
        ? new Date(this.singleItem.deletion_date).toLocaleDateString("it-IT")
        : "";
    },
    subscriptionDate() {
      return this.singleItem.subscription_date != null
        ? new Date(this.singleItem.subscription_date).toLocaleDateString(
            "it-IT"
          )
        : "";
    },
  },
  watch: {
    cityInUse: function () {
      if (this.cityInUse != undefined) {
        let country_filters = {
          query: [],
          filters: {
            "country.id": {
              _eq: this.cityInUse.state.region.country.id,
            },
          },
          sort: {},
          page: 1,
        };
        this.$store.dispatch(FETCH_REGIONS_LIST, country_filters);
        this.selectedCountry =
          this.cityInUse != undefined
            ? this.cityInUse.state.region.country.id
            : {};
        this.selectedRegion =
          this.cityInUse != undefined ? this.cityInUse.state.region.id : {};
        this.selectedState =
          this.cityInUse != undefined ? this.cityInUse.state.id : {};
        this.selectedCity =
          this.cityInUse != undefined ? this.cityInUse.id : {};
      }
    },
    selectedCountry: function () {
      if (this.cityInUse != undefined) {
        let regions_filters = {
          query: [],
          filters: {
            "country.id": {
              _eq: this.selectedCountry,
            },
          },
          sort: {},
          page: 1,
        };
        this.$store.dispatch(FETCH_REGIONS_LIST, regions_filters);
      }
    },
    selectedRegion: function () {
      if (this.cityInUse != undefined) {
        let states_filters = {
          query: [],
          filters: {
            "region.id": {
              _eq: this.selectedRegion,
            },
          },
          sort: {},
          page: 1,
        };
        this.$store.dispatch(FETCH_STATES_LIST, states_filters);
      }
    },
    selectedState: function () {
      if (this.cityInUse != undefined) {
        let cities_filters = {
          query: [],
          filters: {
            "state.id": {
              _eq: this.selectedState,
            },
          },
          sort: {},
          page: 1,
        };
        this.$store.dispatch(FETCH_CITIES_LIST, cities_filters);
      }
    },
  },
};
</script>
