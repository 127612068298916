<template>
  <div class="d-flex flex-row">
    <div
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
        id="kt_profile_aside"
    >
      <div class="card card-custom card-stretch" v-if="citizenInfo">
        <div class="card-body pt-4">
          <div class="d-flex justify-content-end"></div>
          <div class="d-flex align-items-center">
            <div>
              <a
                  class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
              >{{ getDenomination }}</a
              >
              <div class="text-muted">
                <v-icon :title="$t('PAGES.CITIZENS.OVERVIEW.BORN_DATE')" color="green">mdi-cake-variant</v-icon>
                <span>
                  {{
                    new Date(citizenInUse.dateOfDeath).toLocaleString([], {
                      year: "numeric",
                      month: "long",
                      day: "numeric"
                    })
                  }}
                </span>

                <v-icon v-if="citizenInUse.position==2 && citizenInUse.dateOfDeath.length > 1"
                        :title="$t('PAGES.CITIZENS.OVERVIEW.DEAD_DATE')" :color="getPositionColor">{{ getPositionIcon }}
                </v-icon>
                <span v-if="citizenInUse.position==2 && citizenInUse.dateOfDeath.length > 1">
                  {{
                    new Date(citizenInUse.dateOfDeath).toLocaleString([], {
                      year: "numeric",
                      month: "long",
                      day: "numeric"
                    })
                  }}
                </span>
              </div>
              <div class="text-muted">
                <v-icon :title="$t('PAGES.CITIZENS.OVERVIEW.RESIDENCE')" color="green">mdi-map-marker</v-icon>
                <span>{{ getResidence }}</span>
              </div>
            </div>
          </div>
          <v-divider></v-divider>
          <!--end::User-->
          <!--begin::Contact-->
          <div class="py-2">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.CITIZENS.OVERVIEW.FISCAL_CODE") }}:</span
              >
              <span class="text-muted">{{ citizenInfo.fiscalCode }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.CITIZENS.OVERVIEW.BORN_SITE") }}:</span
              >
              <span class="text-muted">{{ getCityOfBorn }}</span>
            </div>
            <div v-if="citizenInUse.residenceAbroad.denomination == undefined">
              <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.CITIZENS.OVERVIEW.RESIDENCE_ADDRESS") }}:</span
              >
                <span class="text-muted">{{ citizenInUse.residenceAddress }} {{
                    citizenInUse.residencePostalCode
                  }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              ></span
              >
                <span class="text-muted">{{ getResidence }}</span>
              </div>
            </div>
            <div v-if="citizenInUse.domicileAbroad.denomination == undefined">
              <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.CITIZENS.OVERVIEW.DOMICILE_ADDRESS") }}:</span
              >
                <span class="text-muted">{{ citizenInUse.domicileAddress }} {{
                    citizenInUse.domicilePostalCode
                  }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              ></span
              >
                <span class="text-muted">{{ getResidence }}</span>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.CITIZENS.OVERVIEW.PHONE") }}:</span
              >
              <span class="text-muted">{{ citizenInfo.phone }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.CITIZENS.OVERVIEW.MOBILE_PHONE") }}:</span
              >
              <span class="text-muted">{{ citizenInfo.mobilePhone }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.CITIZENS.OVERVIEW.FAX") }}:</span
              >
              <span class="text-muted">{{ citizenInfo.fax }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.CITIZENS.OVERVIEW.EMAIL") }}:</span
              >
              <span class="text-muted">{{ citizenInfo.email }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2"
              >{{ $t("PAGES.CITIZENS.OVERVIEW.PEC") }}:</span
              >
              <span class="text-muted">{{ citizenInfo.pec }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <span class="font-weight-bold mr-2">Note:</span>
              <span class="text-muted">{{ citizenInfo.notes }}</span>
            </div>
          </div>
          <!--end::Contact-->
          <!--begin::Nav-->
          <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded py-9"
              role="tablist"
          >
            <div class="navi-item mb-2">
              <a
                  class="navi-link py-4 active"
                  @click="setActiveTab"
                  style="cursor: pointer"
                  data-tab="0"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Design/Layers.svg"/>
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{
                    $t("PAGES.CITIZENS.OVERVIEW.SUB_MENU.OVERVIEW")
                  }}</span>
              </a>
            </div>
            <div class="navi-item mb-2">
              <a
                  class="navi-link py-4"
                  @click="setActiveTab"
                  style="cursor: pointer"
                  data-tab="1"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Design/Layers.svg"/>
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{
                    $t("PAGES.CITIZENS.OVERVIEW.SUB_MENU.VIEW")
                  }}</span>
              </a>
            </div>

            <div class="navi-item mb-2">
              <a
                  class="navi-link py-4"
                  @click="setActiveTab"
                  style="cursor: pointer"
                  data-tab="2"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg
                        src="media/svg/icons/Communication/Shield-user.svg"
                    />
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{
                    $t("PAGES.CITIZENS.OVERVIEW.SUB_MENU.CADASTRE")
                  }}</span>
                <span class="navi-label">
                  <span
                      class="label label-light-danger label-rounded font-weight-bold"
                  >{{ constructionSiteCounter }}</span
                  >
                </span>
              </a>
            </div>
            <div class="navi-item mb-2">
              <a
                  class="navi-link py-4"
                  @click="setActiveTab"
                  style="cursor: pointer"
                  data-tab="3"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Design/Layers.svg"/>
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{
                    $t("PAGES.CITIZENS.OVERVIEW.SUB_MENU.ENERGY")
                  }}</span>
              </a>
            </div>
            <div class="navi-item mb-2">
              <a
                  class="navi-link py-4"
                  @click="setActiveTab"
                  style="cursor: pointer"
                  data-tab="4"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Design/Layers.svg"/>
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{
                    $t("PAGES.CITIZENS.OVERVIEW.SUB_MENU.SACE")
                  }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs class="hide-tabs" v-model="tabIndex">
        <b-tab active>
          <ResumeOverview :single-item="citizenInUse"></ResumeOverview>
        </b-tab>

        <b-tab>
          <citizenInformation :single-item="citizenInUse"></citizenInformation>
        </b-tab>

        <b-tab>
          <!--          <ConstructionSitesList-->
          <!--            :list-items="citizenInUse.construction_sites"-->
          <!--          ></ConstructionSitesList>-->
        </b-tab>

        <b-tab>
          <!--          <HistoryPositionList></HistoryPositionList>-->
        </b-tab>

        <b-tab>
          <!--          <LogHistoryList></LogHistoryList>-->
        </b-tab>

        <b-tab>
          <!--          <KTChangePassword></KTChangePassword>-->
        </b-tab>

        <b-tab>
          <!--          <KTEmailSettings></KTEmailSettings>-->
        </b-tab>
      </b-tabs>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ResumeOverview from "@/view/pages/citizens/resume/ResumeOverview";
import citizenInformation from "@/view/pages/citizens/resume/citizenInformation";
// import ConstructionSitesList from "@/view/pages/companies/construction_sites/List";
// import HistoryPositionList from "@/view/pages/companies/history_position/List";
// import LogHistoryList from "@/view/pages/companies/history_log/List";
// import KTAccountInformation from "@/view/pages/profile/profile-comp/AccountInformation";
// import KTChangePassword from "@/view/pages/profile/profile-comp/ChangePassword";
// import KTEmailSettings from "@/view/pages/profile/profile-comp/EmailSettings";
import {GET_CITIZEN_BY_ID} from "../../../core/services/store/citizens.module";
// import {GET_CITY_BY_ID} from "../../../core/services/store/cities.module";

export default {
  name: "overview-company",
  components: {
    ResumeOverview,
    citizenInformation,
    // KTAccountInformation,
    // KTChangePassword,
    // KTEmailSettings,
    // ConstructionSitesList,
    // HistoryPositionList,
    // LogHistoryList,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("PAGES.CITIZENS.AREA")},
      {
        title: this.$t("PAGES.CITIZENS.OVERVIEW.TITLE"),
      },
    ]);

    if (this.$route.params.id != undefined) {
      this.$store.dispatch(GET_CITIZEN_BY_ID, this.$route.params.id);
    }
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "citizenInUse", "cityInUse"]),
    citizenInfo() {
      return this.citizenInUse != undefined ? this.citizenInUse : null;
    },
    getCityOfBorn() {
      return this.citizenInUse.bornAbroad.denomination != undefined ?
          this.citizenInUse.bornAbroad.denomination + " (" + this.$t('PAGES.CITIZENS.OVERVIEW.FOREIGN') + ")" :
          this.citizenInUse.cityOfBirth.denomination + " (" +
          this.citizenInUse.cityOfBirth.state.abbreviation + ") "
    },
    getFullResidence() {
      return this.citizenInUse.residenceAbroad.denomination != undefined ?
          this.citizenInUse.residenceAbroad.denomination + " (" + this.$t('PAGES.CITIZENS.OVERVIEW.FOREIGN') + ")" :
          this.citizenInUse.cityOfResidence.denomination + " (" +
          this.citizenInUse.cityOfResidence.state.abbreviation + ") "
    },
    getResidence() {
      return this.citizenInUse.residenceAbroad.denomination != undefined ?
          this.citizenInUse.residenceAbroad.denomination + " (" + this.$t('PAGES.CITIZENS.OVERVIEW.FOREIGN') + ")" :
          this.citizenInUse.cityOfResidence.denomination + " (" +
          this.citizenInUse.cityOfResidence.state.abbreviation + ") "
    },
    getDenomination() {
      return this.citizenInUse != undefined
          ? this.citizenInUse.name + " " + this.citizenInUse.surname
          : {};
    },
    getPositionColor() {
      switch (this.citizenInUse.position) {
        case '1':
          return 'green';
        case '2':
          return 'red';
        default:
          return ''
      }
    },
    getPositionIcon() {
      switch (this.citizenInUse.position) {
        case '1':
          return 'green';
        case '2':
          return 'mdi-coffin';
        default:
          return ''
      }
    },
    getSubTitle() {
      return 'AAAAA NBNNNN';
      // return (
      //     this.citizenInUse.denomination +
      //     " (" +
      //     this.citizenInUse.state.abbreviation +
      //     ") - " +
      //     this.citizenInUse.state.region.denomination +
      //     " - " +
      //     this.citizenInUse.state.region.country.denomination
      // );
    },
    getSubTitle2() {
      return this.citizenInUse != undefined ? this.citizenInUse.vat : {};
    },
    getFullName() {
      return (
          this.currentUserPersonalInfo.name +
          " " +
          this.currentUserPersonalInfo.surname
      );
    },
  },
  watch: {
    citizenInUse: function () {
      // this.$store.dispatch(GET_CITY_BY_ID, this.citizenInUse.city.id);
    },
  },
};
</script>
