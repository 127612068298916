<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">
        {{ $t("PAGES.CONSTRUCTION_SITES.WIDGET_RESUME.TITLE") }}
      </h3>
      <div class="card-toolbar">
        <div class="dropdown dropdown-inline">
          <Dropdown2></Dropdown2>
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2" v-if="listItems.length > 0">
      <template v-for="(item, i) in listItems.slice(0, 5)">
        <!--begin::Item-->
        <div
          class="d-flex flex-wrap align-items-center"
          v-bind:key="i"
          v-bind:class="{ 'mb-9': lastElement(i) }"
        >
          <!--begin::Title-->
          <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
            <router-link
              :to="{
                name: 'constructionsites_overview',
                params: { id: item.id },
              }"
              >{{ item.description.slice(0, 50) }}</router-link
            >
            <span class="text-muted font-weight-bold font-size-sm my-1"
              >{{ item.city != undefined ? item.city.denomination : "" }}
              {{ item.address }}</span
            >
            <span class="text-muted font-weight-bold font-size-sm"
              >{{ item.code }}
              <span class="text-primary font-weight-bold">{{
                item.subscription_date != null
                  ? new Date(item.subscription_date).toLocaleDateString()
                  : "---"
              }}</span></span
            >
          </div>
          <!--end::Title-->
          <!--begin::Info-->
          <div class="d-flex align-items-center py-lg-0 py-2">
            <div class="d-flex flex-column text-right">
              <span class="text-dark-75 font-weight-bolder font-size-h4">{{
                item.text4
              }}</span>
              <span class="text-muted font-size-sm font-weight-bolder">{{
                translateStatus(item.status)
              }}</span>
            </div>
          </div>
          <!--end::Info-->
        </div>
        <!--end::Item-->
      </template>
    </div>
    <div class="card-body pt-2" v-else>
      {{ $t("PAGES.CONSTRUCTION_SITES.WIDGET_RESUME.NORESULTS") }}
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import { mapGetters } from "vuex";

export default {
  name: "widget-2",
  data() {
    return {};
  },
  props: ["list-items"],
  components: {
    Dropdown2,
  },
  methods: {
    lastElement(i) {
      if (i === this.listItems.slice(0, 5) - 1) {
        return false;
      } else {
        return true;
      }
    },
    translateStatus(status) {
      return this.$t("PAGES.CONSTRUCTION_SITES.COMMON.STATUS_" + status);
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
};
</script>
