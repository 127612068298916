<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <WidgetLastConstructionSites
          :list-items="[]"
        ></WidgetLastConstructionSites>
      </div>
      <div class="col-lg-6">
        <WidgetLastNotifications></WidgetLastNotifications>
      </div>
    </div>
    <div class="card card-custom gutter-b">
      <!--      http://localhost:8080/#/companies/overview/27137-->
      <!--      <WidgetLastWorkers></WidgetLastWorkers>-->
    </div>
  </div>
</template>

<script>
import WidgetLastConstructionSites from "@/view/pages/citizens/resume/WidgetLastConstructionSites";
import WidgetLastNotifications from "@/view/pages/citizens/resume/WidgetLastNotifications";
// import WidgetLastWorkers from "@/view/pages/companies/resume/WidgetLastWorkers";

export default {
  name: "ResumeOverview",
  props: ["single-item"],
  components: {
    WidgetLastConstructionSites,
    WidgetLastNotifications,
    // WidgetLastWorkers,
  },
};
</script>
